<template>
  <section v-if="blogList.length" class="news-and-article">
    <div class="news-and-article__wrp">
      <BlogRecentlyPosts>
        <h4 class="news-and-article__title">{{ _T("@News & Articles") }}</h4>
        <p class="news-and-article__subtitle">{{ _T("@Everything you need to know about video surveillance from GreenVision") }}</p>
      </BlogRecentlyPosts>
    </div>
  </section>
</template>

<script setup>
  import BlogRecentlyPosts from "~/modules/blog/components/BlogRecentlyPosts.vue";
  import { useContentfulStore } from "~/store/contentfulStore";

  const contentfulStore = useContentfulStore();
  await contentfulStore.fetchBlog(3);

  const blogList = contentfulStore.getLimitedBlog(3);

</script>

<style lang="scss" scoped>
  .news-and-article {
    padding: 0 32px;

    @include bigMobile {
      padding: 0 16px;
    }

    &__wrp {
      @extend %width-main;

      margin: 0 auto;
    }

    &__title {
      @include font(28, 39, 600);
      text-align: center;

      margin-bottom: 8px;

      @include bigMobile {
        @include font(20, 30, 600);
        
        margin-bottom: 0;
      }
    }

    &__subtitle {
      @include font(20, 30, 500);
      text-align: center;
      text-transform: uppercase;

      @include bigMobile {
        @include font(16, 24, 500);
      }
    }
  }
</style>