<template>
  <div ref="sliderWindow" class="home-recommend__overflow">
    <div
      ref="slider"
      class="home-recommend__box"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <h3
        v-for="(category, index) in categories"
        ref="slide"
        :key="index"
        class="home-recommend__subtitle"
        :class="{ active: category.code === currentCategoryCode }"
        @click="handleClick(index)"
      >
        {{ _T(category.title) }}
      </h3>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  currentCategoryCode: { type: String, required: true },
  categories: { type: Array, required: true },
});
const emit = defineEmits(["changeCategory"]);

const slider = ref(null);
const sliderWindow = ref(null);
const slide = ref(null);

const sliderWindowWidth = ref(0);
const slidesWidth = ref(0);
const sliderLength = ref(0);
const slideWidth = ref(0);
const maxStep = ref(0);
const slideGap = 16;

const itemsWidthArray = ref([]);
const showItemsWidth = ref(0);
const showItems = ref(0);

const counter = ref(0);
const translateX = ref(0);
const prevTranslateX = ref(0);

const translateXVar = ref(0);

const activeTouches = ref(false);
const mobileTranslateX = ref(0);
const startPosition = ref(0);
const difference = ref(0);

function handleClick(index) {
  emit("changeCategory", index);
}

function getSliderValues() {
  setTimeout(() => {
    sliderWindowWidth.value = sliderWindow.value.offsetWidth;
    sliderLength.value = props.categories.length;

    slidesWidth.value = slider.value.offsetWidth;
    slideWidth.value = slider.value.children[3].offsetWidth;

    const items = Array.from(slide.value);
    itemsWidthArray.value = items.map((el) => el.offsetWidth);

    itemsWidthArray.value.reduce((a, b) => {
      if (a + b + slideGap < sliderWindowWidth.value) {
        showItems.value++;
        showItemsWidth.value = a + b + slideGap;
        return showItemsWidth.value;
      }
    });

    maxStep.value = sliderLength.value - 1 - showItems.value;

    counter.value = 0;
    translateX.value = 0;
    prevTranslateX.value = translateX.value;
    translateXVar.value = `-${translateX.value}px`;
  }, 500);
}

function nextSlide() {
  if (counter.value < maxStep.value) {
    showItems.value++;

    if (counter.value === 0) {
      translateX.value =
        prevTranslateX.value +
        itemsWidthArray.value[showItems.value] +
        slideGap -
        (sliderWindowWidth.value - showItemsWidth.value);
    } else {
      translateX.value =
        prevTranslateX.value +
        itemsWidthArray.value[showItems.value] +
        slideGap;
    }

    prevTranslateX.value = translateX.value;
    counter.value++;
  }
  translateXVar.value = `-${translateX.value}px`;
}

function prevSlide() {
  if (counter.value > 1) {
    counter.value--;
    translateX.value =
      prevTranslateX.value - itemsWidthArray.value[showItems.value] - slideGap;

    showItems.value--;
  } else if (counter.value === 1) {
    translateX.value = 0;
    showItems.value--;
    counter.value--;
  } else {
    counter.value = 0;
    translateX.value = 0;
  }

  prevTranslateX.value = translateX.value;
  translateXVar.value = `-${translateX.value}px`;
}

function handleTouchStart(event) {
  if (window.innerWidth < 1024) {
    activeTouches.value = true;
    mobileTranslateX.value = event.touches[0].clientX;
    startPosition.value = event.touches[0].clientX;
  }
}

function handleTouchMove(event) {
  if (window.innerWidth < 1024) {
    const positionMove = event.touches[0].clientX;
    const diff = positionMove - mobileTranslateX.value;
    const fingerSpace = 30;

    if (
      startPosition.value - positionMove < fingerSpace &&
      startPosition.value - positionMove > -fingerSpace
    ) {
      return false;
    } else {
      if (activeTouches.value) {
        if (!mobileTranslateX.value) return false;

        difference.value = diff;
        difference.value > 0 ? prevSlide() : nextSlide();

        mobileTranslateX.value = null;
      }
    }
  }
}

function handleTouchEnd() {
  if (window.innerWidth < 1024) {
    activeTouches.value = false;
  }
}

onMounted(() => {
  getSliderValues();

  window.addEventListener("resize", getSliderValues);
});

onUnmounted(() => {
  window.removeEventListener("resize", getSliderValues);
});
</script>

<style lang="scss" scoped>
.home-recommend {
  &__overflow {
    @extend %width-main;

    @include bigMobile {
      overflow: scroll;
    }
  }
  &__box {
    @include flex-container(row, space-between, center);

    margin-bottom: 11px;

    @include bigMobile {
      max-width: 1440px;
      width: max-content;

      @include flex-container(row, flex-start, center);

      //transform: translateX(v-bind(translateXVar));
      transition: transform 0.5s ease-in-out;
      margin: 0 auto 8px auto;
    }
  }

  &__subtitle {
    cursor: pointer;
    position: relative;

    @include font(20, 30, 600);
    color: var(--color-ink-lighter);
    text-transform: uppercase;

    transition: color 0.3s ease-in-out;

    padding: 8px;

    &:before {
      content: "";
      width: 100%;
      height: 3px;

      position: absolute;
      top: 0;
      left: 0;

      transform: rotateY(90deg);
      transition:
        transform 0.5s ease-in-out,
        background-color 0.3s ease-in-out;
      opacity: 1;
    }

    &:hover {
      color: var(--color-primary-base);
    }
    &.active {
      color: var(--color-primary-base);

      &::before {
        background-color: var(--color-primary-base);
        transform: rotateY(0deg);
      }
    }

    @include smallestScreen {
      @include font(16, 22, 600);
    }

    @include bigMobile {
      max-width: 100%;

      @include font(18, 24, 600);
      text-align: center;
      text-transform: capitalize;

      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }

    @include mobile {
      @include font(14, 16, 600);
    }
  }
}
</style>
