<template>
  <div class="pagination-slider"></div>
</template>

<style lang="scss">
 .pagination-slider {
    .swiper-pagination-bullet {
      background-color: white; 

      opacity: 1;

      transition: width .3s ease-in-out, background-color .3s ease-in-out, border-radius .3s ease-in-out;

      &.swiper-pagination-bullet-active {
        width: 16px;

        background-color: var(--color-primary-base);

        border-radius: 10px;
      }
    }
  }
</style>