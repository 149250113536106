<template>
  <div 
    class="slide"
    :style="[
      { '--title-color': getItemColor(data?.colorTitle) },
      { '--description-color': getItemColor(data?.colorText) },
      { '--button-color': getItemColor(data?.colorBtn) },
      { '--button-background': getItemColor(data?.colorBtnBack) },
    ]"
  >
    <div class="slide__wrp">
      <div class="slide__content">
        <div class="slide__txts">
          <!--
          <div class="slide__label">
            <img src="~~/assets/icons/label_new.svg" class="slide__label-icon">
          </div>
          -->
          <NuxtLink
            :to="`${getUserLanguage.urlPrefix}/${
              data?.url[getUserLanguage.name]
            }`"
            class="slide__title"
          >
            {{ itemTitle }}
          </NuxtLink>

          <div class="slide__img-wrp-mobile">
            
            <img :src="useNormalizeCtfUrl(itemImage)" :alt="itemTitle" :title="itemTitle">
          </div>

          <p class="slide__description">{{ itemDescription }}</p>
          
          <NuxtLink
            class="slide__link"
            :to="`${getUserLanguage.urlPrefix}/${
              data?.url[getUserLanguage.name]
            }`"
          >
            <span>{{ itemButtonTitle }}</span>
          </NuxtLink>
        </div>

        <div class="slide__img-wrp">
          <span class="slide__img-border"></span>
          <img :src="useNormalizeCtfUrl(itemImage)" :alt="itemTitle" :title="itemTitle">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useLanguageFilterStore } from "~~/store/languageFilterStore";
  import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

  const languageStore = useLanguageFilterStore();
  const { getUserLanguage } = storeToRefs(languageStore);

  const props = defineProps({
    data: { type: Object, required: true },
  });

  const itemTitle = computed(() => props.data?.title?.[getUserLanguage.value.name]);
  const itemDescription = computed(() => props.data?.description?.[getUserLanguage.value.name]);
  const itemButtonTitle = computed(() => props.data?.btn?.[getUserLanguage.value.name]);
  const itemImage = computed(() => props.data.imgMain.ru.fields.file.ru.url);

  function getItemColor(color, defaultColor = 'white') {
    return color ? color.ru : defaultColor;
  }
</script>

<style lang="scss" scoped>
  .slide {
    --title-color: white;
    --description-color: white;
    --button-color: white;
    --button-background: white;

    min-height: 432px;

    @include flex-container(column, center, center);

    @include bigMobile {
      min-height: 500px;
    }

    &__wrp {
      width: 100%;

      padding: 16px 80px;

      @include bigMobile {
        padding: 16px;
      }
    }

    &__content {
      max-width: 1100px;
      width: 100%;

      @include flex-container(row, space-between, center);

      margin: 0 auto;
      gap: 16px;

      @include bigMobile {
        justify-content: center;
      }
    }

    &__txts {
      max-width: 602px;
      width: 100%;

      @include flex-container(column, flex-start, flex-start);

      position: relative;

      gap: 16px;

      @include bigMobile {
        @include flex-container(column, center, center);
      }
    }

    &__label {
      position: absolute;
      top: -65%;
      left: -15%;
    }

    &__title {
      @include font(36, 43, 700);
      color: var(--title-color);

      @include bigMobile {
        max-width: 90%;

        @include font(18, 22, 700);
        text-align: center;
      }
    }

    &__description {
      @include font(16, 22);
      color: var(--description-color);

      @include bigMobile {
        @include font(14, 20);
        width: 90%;
        text-align: center;
      }

      @include mobile {
        @include font(12, 18);
      }
    }

    &__img-wrp-mobile {
      width: 220px;
      height: 220px;
      
      display: none;

      @include bigMobile {
        display: block;
      }
    }

    &__img-wrp {
      flex: 0 0 350px;
      height: 350px;

      position: relative;

      @include flex-container(column, center, center);

      @include bigMobile {
        display: none;
      }

      &::before,
      &::after {
        content: '';

        width: 36px;
        height: 36px;

        display: block;

        position: absolute;
        
        z-index: 0;
      }

      &::after {
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        
        bottom: 0;
        left: 0;
      }

      &::before {
        border-top: 1px solid white;
        border-right: 1px solid white;
        
        top: 0;
        right: 0;
      }
    }

    &__img-border {
      @include bigMobile {
        display: none;
      }

      &::before,
      &::after {
        content: '';

        width: 64px;
        height: 64px;

        display: block;

        position: absolute;
        
        z-index: 0;
      }

      &::after {
        border-top: 1px solid white;
        border-left: 1px solid white;
        
        top: 0;
        left: 0;
      }

      &::before {
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        
        bottom: 0;
        right: 0;
      }
    }

    &__link {
      max-width: 236px;
      width: 100%;

      @include flex-container(row, center, center);

      position: relative;

      background-color: var(--button-background);

      border-radius: 30px;

      padding: 8px 16px;

      span {
        @include font(18, 24);
        color: var(--button-color);

        @include smallScreen {
          @include font(16, 24);
        }

        @include mobile {
          @include font(14, 20);
        }
      }

      @include bigMobile {
        align-self: flex-start;

        margin: 0 auto;
      }
    }
  }
</style>