<template>
  <section class="home-categories">
    <div class="home-categories__container">
      <div
        v-for="(category, index) in items"
        :key="index"
        class="home-categories__item"
      >
        <NuxtLink
          :to="
            getUserLanguage.urlPrefix +
            '/' +
            category.fields.url[getUserLanguage.name]
          "
        >
          <img
            class="home-categories__icon"
            :src="useNormalizeCtfUrl(category.fields?.img.ru.fields.file.ru.url)"
            :alt="category.fields.img.ru.fields.title[getUserLanguage.name]"
          />
          <h4 class="home-categories__title">{{ category.fields.title[getUserLanguage.name] }}</h4>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import { useCategoriesStore } from "~/store/categoriesStore";
import CategoryIcon from "~~/modules/shared/components/categoryIcons/CategoryIcon.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const categoriesStore = useCategoriesStore();
const { getCategoryByCode } = storeToRefs(categoriesStore);

const contentful = useContentfulStore();
await contentful.fetchTopCategories();
await contentful.fetchCategoryUnderFilter();
const config = useRuntimeConfig();
const items = contentful.getTopCategories;

const categories = [
  {
    img: "home-category-1",
    title: "@CCTV Cameras",
    code: "2712",
  },
  {
    img: "home-category-2",
    title: "@DVRs for video surveillance",
    code: "1269",
  },
  {
    img: "home-category-3",
    title: "@CCTV kits",
    code: "3251",
  },
  {
    img: "home-category-4",
    title: "@ACS",
    code: "7069",
  },
  {
    img: "home-category-5",
    title: "@network hardware",
    code: "12871",
  },
  {
    img: "home-category-6",
    title: "@Power supplies",
    code: "2657",
  },
  {
    img: "home-category-1",
    title: "@CCTV Cameras",
    code: "2712",
  },
  {
    img: "home-category-2",
    title: "@DVRs for video surveillance",
    code: "1269",
  },
  {
    img: "home-category-3",
    title: "@CCTV kits",
    code: "3251",
  },
  {
    img: "home-category-4",
    title: "@ACS",
    code: "7069",
  },
  {
    img: "home-category-5",
    title: "@network hardware",
    code: "12871",
  },
  {
    img: "home-category-6",
    title: "@Power supplies",
    code: "2657",
  },
];
</script>

<style lang="scss">
.home-categories {
  background-color: var(--color-primary-lightest);

  padding: 16px 32px;

  @include bigMobile {
    background-color: inherit;

    padding: 0 16px;
  }

  &__container {
    @extend %width-main;

    @include flex-container(row, center);
    flex-wrap: wrap;

    margin: 0 auto;
    gap: 16px;

    @include bigMobile {
      @include flex-container(row, center);
    }
  }

  &__item {
    --gap: 16px;

    @include flex-container(column, flex-start);
    
    @include set-item-count-in-row(6);

    text-align: center;

    cursor: pointer;

    background-color: white;
    box-shadow: 0px 3px 11px 0px #602C8833;

    border-radius: 16px;

    padding: 16px;

    @include smallScreen {
      @include set-item-count-in-row(4);
    }

    @include bigMobile {
      @include set-item-count-in-row(3);

      padding: 8px;
    }

    @include mobile {
      @include set-item-count-in-row(2);
    }

    &:hover {
      & .home-categories__title {
        color: #0C95E3;
      }
    }

    & a {
      height: 100%;

      @include flex-container(column, flex-start);

      gap: 4px;
    }
  }

  &__icon {
    width: 200px;
    height: 166px;
    margin: 0 auto;
  }

  &__title {
    @include font(14, 20, 500);
    color: var(--color-ink-base);

    transition: color .3s ease-in-out;
  }
}
</style>
