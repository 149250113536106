<template>
  <section class="home-recommend">
    <span v-if="!products" class="ui-loader sticky" />
    <div v-else class="home-recommend__container">
      <h2 class="home-recommend__title">{{ _T("@Featured") }}</h2>
      <hr />
      <RecommendedCategory
        :categories="categories"
        :current-category-code="currentCategoryCode"
        @change-category="onCategoryChange"
      />
      <div class="home-recommend__content">
        <CardProductRecommend
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import RecommendedCategory from "~/modules/home/components/recommendedCategories/RecommendedCategory.vue";
import { useCategoriesStore } from "~/store/categoriesStore";
import CardProductRecommend from "~/modules/home/components/recommendedCategories/CardProductRecommend.vue";
import { useAPI } from "~/uses/useMyFetch";
import { Product } from "~/models/product.model";

const categoriesStore = useCategoriesStore();
const { getCategoryByCode } = storeToRefs(categoriesStore);

const products = ref(null);
const isCurrenSizeMobile = ref(false);
const { width: windowSize } = useWindowSize();

const currentCategoryCode = ref("13116");

const categories = [
  {
    title: "Аккумуляторы",
    code: "13116",
  },
  {
    title: "Камеры Видеонаблюдение",
    code: "13130",
  },
  {
    title: "ИБП",
    code: "13124",
  },
  {
    title: "Альтернативная энергетика",
    code: "13177",
  },
  {
    title: "Электроинструменты",
    code: "13239",
  },
  /*
  {
    title: "Дача, сад и огород",
    code: "13247",
  },*/
];

const fetchProducts = () => {
  useAPI("/catalog/product/list/all", {
    params: {
      pageSize: isCurrenSizeMobile.value ? 4 : 10,
      pageNum: 1,
      categoryId: getCategoryByCode.value(currentCategoryCode.value)?.id,
    },
  }).then((data) => {
    products.value = data.items.map((dto) => new Product(dto));
  });
};

if (process.client) {
  isCurrenSizeMobile.value = windowSize.value < 1024;
  fetchProducts();
}

async function onCategoryChange(index) {
  currentCategoryCode.value = categories[index].code;
  fetchProducts();
}

async function checkWindowSize() {
  if (isCurrenSizeMobile.value !== windowSize.value < 1024) {
    isCurrenSizeMobile.value = !isCurrenSizeMobile.value;

    fetchProducts();
  }
}

onMounted(() => {
  fetchProducts()
  checkWindowSize();
  window.addEventListener("resize", checkWindowSize);
});
</script>

<style lang="scss" scoped>
.home-recommend {
  min-height: 200px;

  display: flex;

  padding: 0 16px;
  margin-bottom: 78px;

  &__container {
    @extend %width-main;
    margin: auto;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px 16px;
  }

  & hr {
    height: 1px;
    display: block;

    background-color: var(--color-sky-dark);
    margin: 0;
  }

  &__title {
    @include font(28, 39, 600);
    color: var(--color-ink-base);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
    }
  }
}
</style>
