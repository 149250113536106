<template>
  <article 
    class="main-slider"
    :style="[
      { '--background-color': getBackgroundColor(items[currenIndex]) },
      { '--background-image': getBgImage(items[currenIndex]) },
    ]"
  >
    <div class="main-slider__wrp">
      <div class="main-slider__swiper">
        <Swiper 
          @slideChange="onSlideChange"
          :speed="1000"
          :slides-per-view="1" 
          :navigation="swiperNavigation"
          :loop="true"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false
          }"
          :pagination="{
            el: '.swiper-pagination',
            clickable: true,
          }"
          :modules="modules"
        >
          <SwiperSlide v-for="(item, index) in filteredItems" :key="index">
            <Slide :data="item.fields" />
          </SwiperSlide>
        </Swiper>

        <PaginationSlider class="swiper-pagination"/>

        <tempalate v-if="items.length > 1">
          <NavigationButton 
            class="swiper-button-prev main-slider__btn-prev" 
            
          />
          <NavigationButton 
            class="swiper-button-next main-slider__btn-next" 
            :isNext="true"
          />
        </tempalate>
      </div>
    </div>
  </article>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import Slide from "./UI/Slide.vue";
import NavigationButton from "./UI/NavigationButton.vue";
import PaginationSlider from "./UI/PaginationSlider.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const contentful = useContentfulStore();
await contentful.fetchSlider();
const config = useRuntimeConfig();
const items = contentful.getSlider;

const modules = [Navigation, Autoplay, Pagination];
const swiperNavigation = {
  nextEl: '.main-slider__btn-next',
  prevEl: '.main-slider__btn-prev',
};

const currenIndex = ref(0);

const filteredItems = computed(() => {
  return items ? items.sort((a, b) => a.fields.position.ru - b.fields.position.ru) : [];
});

const onSlideChange = swiper => {
  currenIndex.value = swiper.realIndex;
};

function getBackgroundColor(item) {
  const DEFAULT_COLOR = "#1f1f1f";

  return item.fields.colorBack ? item.fields.colorBack.ru : DEFAULT_COLOR;
}

function getBgImage(item) {
  return `url('${useNormalizeCtfUrl(item.fields?.backgroundImage.ru.fields.file.ru.url)}')`;
}
</script>

<style lang="scss" scoped>
  .main-slider {
    --background-color: #009b3e;
    --background-image: none;

    padding: 0 32px;

    @include bigMobile {
      padding: 0 16px;
    }

    &__wrp {
      max-width: 1440px;
      width: 100%;

      margin: 0 auto;
    }

    &__swiper {
      position: relative;

      background-color: var(--background-color);
      background-image: var(--background-image);
      background-repeat: no-repeat;
      background-size: cover;

      border-radius: 40px;

      transition: background .3s ease-in-out;

      .swiper-pagination {
        bottom: 24px;

        @include bigMobile {
          bottom: 16px;
        }
      }

      .swiper-button-prev {
        left: 32px;

        @include smallScreen {
          left: -2%;
        }
      }

      .swiper-button-next {
        right: 32px;

        @include smallScreen {
          right: -2%;
        }
      }
    }
  }
</style>