<template>
  <div class="faq-item__item" @click="isShow = !isShow">
    <div class="faq-item__box" :class="{ active: isShow }">
      <div class="faq-item__subtitle-box">
        <h3 class="faq-item__subtitle">
          {{ item.title?.[getUserLanguage.name] }}
        </h3>
        <div class="faq-item__plus-box">
          <span
            class="faq-item__plus"
            :class="{ active: isShow }"
          ></span>
        </div>
      </div>
      <Transition name="descriptions">
        <div v-if="isShow" class="faq-item__description">
          <div
            class="faq-item__description-item ui-rich-text"
            v-html="
              item.description?.[getUserLanguage.name].content[0]
                .content[0].value
            "
          ></div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const props = defineProps({
  item: { type: Object, required: true },
});

const isShow = ref(false);
</script>

<style lang="scss" scoped>
.faq-item {
  &__item {
    width: 100%;

    padding: 18px 16px;
    margin: auto;
  }

  &__box {
    cursor: pointer;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &:hover {
      & .faq-item__subtitle {
        border-color: black;
      }

      & .faq-item__plus,
      & .faq-item__plus::before {
        transform: rotate(90deg) scale(1.1);
      }
    }

    &.active:hover {
      & .faq-item__subtitle {
        border-color: transparent;
      }

      & .faq-item__plus {
        transform: rotate(180deg) scale(1.3);
      }
    }
  }

  &__subtitle-box {
    @include flex-container(row, space-between, center);

    gap: 20px;
  }

  &__subtitle {
    @include font(20, 28, 600);

    border-bottom: 1px solid transparent;

    transition: border-color .3s ease-in-out;

    @include bigMobile {
      @include font(16, 22, 600);
    }
  }

  &__description {
    width: 75%;

    @include bigMobile {
      width: 100%;
    }
  }

  &__description-item {
    @include font(16, 22, 400);

    margin-top: 16px;

    &li:not(:last-of-type) {
      margin-bottom: 16px;
    }

    @include bigMobile {
      @include font(14, 20, 400);
    }
  }

  &__plus-box {
    cursor: pointer;
    width: 32px;
    height: 32px;

    position: relative;
    @include flex-container(row, center, center);
    align-self: start;

    padding: 8px;
  }

  &__plus {
    width: 16px;
    height: 2px;

    display: block;

    background-color: var(--color-ink-base);
    transition:
      transform .3s ease-in-out,
      background-color .3s ease-in-out;

      border-radius: 3px;

    &::before {
      content: "";
      width: 16px;
      height: 2px;

      display: block;
      position: absolute;
      z-index: 2;

      background-color: var(--color-ink-base);

      transform: rotate(90deg);
      transition:
        transform .3s ease-in-out,
        background-color .3s ease-in-out;

        border-radius: 3px;
    }

    &.active {
      &::before {
        display: none;
      }
    }
  }
}

.descriptions-enter-from {
  transform: translateY(-30px);
  opacity: 0;
}

.descriptions-enter-to {
  transform: translateY(0px);
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
}

.descriptions-leave-to {
  transform: translateY(-30px);
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
  opacity: 0;
}
</style>
