<template>
  <section v-if="faqList.length" class="faq">
    <div class="faq__container">
      <h2 class="faq__title">{{ _T("@FAQ") }}</h2>
      <div class="faq__items">
        <Item
          v-for="(item, index) in faqList"
          :key="index"
          :item="item.fields"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";

import Item from "./UI/Item.vue";

const contentfulStore = useContentfulStore();
await contentfulStore.fetchFaq();

const faqList = contentfulStore.getFaq;
</script>

<style lang="scss" scoped>
.faq {
  background-color: #602C880D;

  padding: 0 16px;

  @include bigMobile {
    background-color: transparent;
  }

  &__container {
    margin: auto;
    padding: 32px 0;

    @include bigMobile {
      background-color: #602C881A;
      border-radius: 8px;

      padding: 16px 0;
    }
  }

  &__items {
    max-width: 912px;
    width: 100%;

    @include flex-container(column, center, center);

    margin: 0 auto;
    gap: 4px;
  }

  &__title {
    @include font(28, 39, 600);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(20, 30, 600);
    }
  }
}
</style>
